#admin-circles,
#admin-circles-leave,
.communities-directory {

  /* Fix on Join button in admin (circles + projects) */
  /* Styles on buttons and .children-link don't work because this input is inside too many elements. */
  /* And no I can't add that stupid icon because it'a an input. */
  /* But now, with 0.16, it's a button and you can :D */
  /*.join-button {

    input,
    button {
      padding: 9px 20px;
      border-radius: 16.5px;
      height: 33px;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      background-color: var(--color-secondary);
      color: white;
      border: 1px solid var(--color-secondary);

      &:before {
        font-size: 15px;
        margin-right: 6px;
        content: "\e079";
        font-family: simple-line-icons;
        font-style: normal;
        font-weight: 400;
        speak: none;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      &:hover {
        background: white;
        color: var(--color-secondary);
      }
    }

    @media (max-width: 768px) {

      &.sm-full {

        input,
        button {
          width: 100%;
        }
      }
    }
  }*/

  .masonry>div:nth-child(1) {
    /* change from directory (nth-child(2))*/
    display: flex !important;
    flex-wrap: wrap;
    align-items: center;

    .circle-subtitle-custom {
      height: 32px;
    }
  }

  .segment.hover:hover {
    box-shadow: 0 0 16px 0 rgba(46, 63, 88, 0.34);
    bottom: 2px;
    cursor: pointer;
  }

  orbit-admin-circle-name:hover {
    text-decoration: underline;
  }

  orbit-admin-community-logo {
    height: 76px;
    width: 100%;
    display: block;

    div {
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .community-profile solid-multiple {

    [name="address_line1"]::before {
      font-family: 'material-design-icons';
      content: "\F34E";
      position: relative;
      top: 2px;
      color: var(--color-third);
      font-size: 20px;
      margin-right: 10px;
    }
  
    [name="address_line2"] {
      display: block;
      margin-left: 30px;
    }
  }

  orbit-communities-profile-logo {
    height: 155px;
    width: 100%;
    display: block;

    div {
      display: block;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .tab.active {
    font-weight: bold;
    border-bottom: 5px solid var(--color-third);
    padding-bottom: 10px;
  }

  .communities-profile-picture {
    
    >div:not(:empty) {
      height: 200px;

      div:first-child {
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }

    .picture-empty {
      display: none;
    }
  }

  .communities-map {

    .leaflet-popup-content-wrapper {
      border-radius: 5px;

      .leaflet-popup-content {
        width: 340px !important;
        margin: 0;

        .description {
          height: 32px;
        }

        .title:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }

    .leaflet-popup-close-button {
      display: none;
    }
  }

  .edit-img {

    input[type="file"] {
      display: block;
      margin-top: 10px;
    }

    img {
      display: block;
      margin-top: 15px;
    }

    &.edit-logo {

      img{
        width: 280px;
      }
    }

    &.edit-picture {

      &>div {
        position: relative;

        button {
          margin-top: 10px;
          color: #636363;
          font-weight: bold;
          line-height: 19px;
          font-size: 22px;
        }
      }
      
      img {
        width: 100%;
      }
    }
  }

  .edit-address {

    form {
      display: table-row;
    }

    .segment.table-cell {
      display: table-cell;
      border-bottom: 1px solid #C9C8C8;
      border-right: 1px solid #C9C8C8;
      height: 60px;
      padding: 10px;
      vertical-align: middle;
      width: 250px;
    }

    input[type='submit'] {
      display: none;
    }
  }

  @media (max-width: 768px) {

    .tabs {

      &>li {
        color: #3C3F57;
        font-size: 13px;
        letter-spacing: 0.18px;
        line-height: 17px;

        &>.tab {
          border-radius: 5px;
          box-shadow: 0 0 6px 0 rgba(46, 63, 88, 0.17);

          &.active {
            color: var(--color-primary);
            border: 1px solid var(--color-primary);
            box-shadow: none;
          }
        }
      }
    }

    .community-profile-logo {
      border-bottom: 1px solid #E4E9F1;
    }

    orbit-communities-profile-logo {
      height: 100px;
    }

    .communities-profile-picture {
      
      >div:not(:empty) {
        height: 180px;
      }
    }

    .mobile-map-search-field {
      position: fixed;
      top: 70px;
      z-index: 1000;
      width: 97%;

      button[type='submit'] {
        background: var(--color-secondary) !important;
        color: white !important;

        &:hover {
          background: white !important;
          color: var(--color-secondary) !important;
        }
      }
    }

    .communities-map {
      height: calc(100vh - 50px) !important;

      .leaflet-top.leaflet-left {
        display: none;
      }

      .leaflet-popup-close-button {
        display: block;
        font-size: 20px;
        margin-top: 5px;
        margin-right: 5px;
      }
    }

    .edit-img {

      &.edit-logo {

        img {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 768.01px) {
    
    .communities-profile-picture {

      .picture-empty {
        display: flex;
        justify-content: center;
        align-items: center;

        object {
          height: 100px;
          width: 100px;
          object-fit: cover;
          object-position: center;
        }
      }

      &:nth-child(2)>.picture-empty {
        background: #E4E9F1;

        svg {
          fill: #D0D4DA;
        }
      }

      &:nth-child(1)>.picture-empty,
      &:nth-child(3)>.picture-empty {
        background: #E9F2FF;

        svg {
          fill: #D7E2F3;
        }
      }
    }
  }
}