#admin-projects-create .form solid-multiple-form {
    height: 100%;
    button {
        border: 1px solid var(--color-secondary);
        text-transform: uppercase;
        color: var(--color-secondary);
        background-color: white;
        font-weight: 700;
        padding: 9px 20px;
        font-size: 12px;
        line-height: 14px;
        border-radius: 16.5px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
    button:hover {
        background-color: var(--color-secondary);
        color: white;
    }
}
#projects-edit .edit-businessprovider form {
    display: table-row;    
    border-bottom: 1px solid #C9C8C8;
    border-right: 1px solid #C9C8C8;
    text-align: center;
    .segment.table-cell {
        border-bottom: 1px solid #C9C8C8;
        border-right: 1px solid #C9C8C8;
        height: 60px;
        padding: 10px;
    }
    [type="submit"] {
        margin: 19px auto;
    }
}
#projects-information .display-businessprovider {
    [name="name"]::after {
        content: " - ";
        margin-left: 5px;
    }
    [name="fee"]:after {
        content: " %";
    }
    > div > solid-display:not(:first-child) [name="titlebusinessprovider"] {
        display: none;
    }
}
