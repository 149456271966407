.index-community {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  >div {
    min-height: 75%;
    margin-top: 100px;
    padding-bottom: 70px;

    @media (max-width: 768px) {
      margin: 0;
      min-height: 100%;
    }

    .community-logo {
      max-width: 100%;
      height: 100px;
      margin-top: 70px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .community-button {
      height: 80px;
      border-radius: 5px;
    }

    .community-button-flexed {
      flex-basis: 33%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .community-button-flexed-large {
      flex-basis: 66%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .community-flex-container {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      >div>solid-display {
        display: contents;
        >div>orbit-index-select-community {
          display: contents;
        }
      }
    }

    .community-button-flex-container {
      display: flex;
      align-items: center;
      justify-content: center;

      >solid-display,
      orbit-index-community-logo,
      orbit-index-community-text {
        display: contents;
      }
    }
  }

  solid-form-text-label,
  solid-form-password-label,
  orbit-input-type-email {
    color: #5D7393;
  }

  label {
    text-align: left;
  }

  [type="submit"] {
    float: initial !important;
  }
}

.communities-directory #communities-filter .search-button button {
  background-color: transparent !important;

  &:hover {
    background-color: var(--color-secondary) !important;
  }
}
