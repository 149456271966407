@charset "UTF-8";

@import "@startinblox/orbit-styling-framework/dist/index.css";

@font-face {
  font-family: 'ConverseFontAwesomeBrands';
  font-style: normal;
  font-weight: normal;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-brands-400.svg#fontawesome") format("svg");
}

@font-face {
  font-family: 'ConverseFontAwesomeRegular';
  font-style: normal;
  font-weight: 400;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-regular-400.svg#fontawesome") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ConverseFontAwesomeSolid';
  font-style: normal;
  font-weight: 900;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.eot");
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.woff2") format("woff2"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.woff") format("woff"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.ttf") format("truetype"), url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/webfonts/fa-solid-900.svg#fontawesome") format("svg");
}


solid-display>div {
  display: contents !important;
}

@import 'header';
@import 'left-nav';
@import 'content';
@import 'about';
@import 'members';
@import 'job-offers';
@import 'chat';
@import 'forms';
@import 'communities';
@import 'projects';
@import 'admin-circles';
@import 'admin-projects';

nav#main__menu {
  @media (min-width: 768.01px) {
    width: 250px;
  }
}

main#content {
  @media (min-width: 768.01px) {
    width: calc(100% - 250px);
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.admin-name-ellipsis {
    display: inline-block;
    width: 75%;
  }
}

orbit-menu-empty+orbit-menu-empty {
  display: none;
  /* Duplicate widget generation? */
}

.swal2-container {
  z-index: 50000 !important;
}

.global-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Style that already exists in the framework but strangely I can't find it in the inspector. So I duplicate it here */
.two-lines-ellipsis {
  display: -webkit-box !important;
}

.link-hover {
  text-decoration: underline;
  &:before {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.submit-button {
  float: right;

  button {
    line-height: 1;

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.search-button {

  button {

    @media (max-width: 768px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
}

.add-member-button {

  button[type='submit'] {

    @media (max-width: 768px) {
      width: 100%;
    }
  }
}

.children-button-icon {

  button {
    display: inline-flex;
    justify-content: center;
    line-height: 15px;

    &::before {
      vertical-align: middle;
    }
  }
}

.button-icon {

  display: inline-flex;
  justify-content: center;
  line-height: 15px;

  &::before {
    vertical-align: middle;
  }
}
